// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { getAdminsCollections, postAdminsFormData } from '@/services/setting.js'

// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'

export default {
  name: 'hotSearchEdit',
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      getAdminsCollections().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[4].options = res.data.collections.locales
          this.formData[0].createDateItem[6].options = res.data.collections.roles
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      const params = {
        admin_user: obj
      }
      params.admin_user.remote_avatar_url = params.admin_user.avatar_url
      postAdminsFormData(params).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'adminsList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    }
  }
}
