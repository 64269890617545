import i18n from '@/i18n/index'

export default {
  formData: [{
    title: i18n.t('form.store.generalInformation'),
    name: 0,
    createDateItem: [{
      span: 12,
      prop: 'avatar_url',
      name: 'image',
      label: i18n.t('form.members.avatar'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        limit: 1
      }
    }, {
      span: 12,
      prop: 'email',
      name: 'input',
      label: i18n.t('form.setting.emailAddress'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.setting.emailAddress') }) }
        ]
      }
    }, {
      span: 12,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.common.name'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.common.name') }) }
        ]
      }
    }, {
      span: 12,
      prop: 'phone_number',
      name: 'input',
      type: 'number',
      label: i18n.t('form.store.phoneNumber'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
      }
    }, {
      span: 12,
      prop: 'locale',
      name: 'select',
      options: [],
      label: i18n.t('form.setting.locale'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        filterable: true,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.setting.locale') }) }
        ]
      }
    }, {
      span: 12,
      prop: 'remark',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.common.remark'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        rows: 4
      }
    }, {
      span: 12,
      prop: 'role',
      name: 'select',
      options: [],
      label: i18n.t('form.setting.role'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.setting.role') }) }
        ]
      }
    }, {
      span: 12,
      prop: 'password',
      name: 'input',
      label: i18n.t('form.setting.password'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.setting.password') }) }
        ]
      }
    }, {
      span: 12,
      prop: 'password_confirmation',
      name: 'input',
      label: i18n.t('form.setting.cmpassword'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.setting.cmpassword') }) }
        ]
      }
    }]
  }]
}
